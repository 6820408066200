import { Fragment, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import CheckMarkIcon from 'assets/icons/check-mark.svg';
import ErrorIcon from 'assets/icons/error.svg';

import { CustomButton } from 'components';
import { YesNoModal } from 'components/CustomModal/YesNoModal/YesNoModal';
import { CellElement } from 'components/GridElements/CellElement/CellElement';
import { ColumnType } from 'components/GridElements/GridCommonConfigs';
import { BuildingFilter } from 'components/GridElements/GridFilters/AvailableFilters/BuildingFilter';
import { ServiceFilter } from 'components/GridElements/GridFilters/AvailableFilters/ServiceFilter';
import * as FiltersStyles from 'components/GridElements/GridFilters/FiltersList/FiltersList.styled';
import { OverlayLoader } from 'components/Loaders/OverlayLoader';
import { useAppDispatch } from 'hooks/useAppSelector';
import { useConfirmationModal } from 'hooks/useConfirmationModal';

import { getBuildings } from 'store/buildings/buildings.actions';
import { RootState } from 'store/rootReducer';
import { getServices } from 'store/services/services.actions';
import { clearServicesList } from 'store/services/services.slice';
import {
  getTimeSlots,
  GetTimeSlotsParams,
} from 'store/time-slots/time-slots.actions';
import { TIME_SLOT_STATUSES } from 'store/time-slots/time-slots.constants';
import { clearTimeSlotsList } from 'store/time-slots/time-slots.slice';
import { TimeSlot } from 'store/time-slots/time-slots.types';
import { TABLE_CONTAINER_STYLES, TABLE_STYLES } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/HelperFunctions';

import { AddScheduleForm } from '../Forms/AddScheduleForm/AddScheduleForm';
import { useAddTimeSlotsModal } from '../Forms/AddScheduleForm/useAddTimeSlotsModal';
import { TIME_SLOTS_EDITOR_TYPES } from '../Forms/ScheduleForms.constants';
import { getScheduleListConfig } from './ScheduleList.config';
import { SCHEDULE_PAGES } from './ScheduleList.constants';
import * as S from './ScheduleList.styled';
import { ScheduleListActions } from './ScheduleListActions';
import { WeekFilter } from './WeekFilter/WeekFilter';

interface ScheduleListProps {
  schedulePageType: SCHEDULE_PAGES;
  id: string;
}

export const ScheduleList = ({ schedulePageType, id }: ScheduleListProps) => {
  const dispatch = useAppDispatch();
  const {
    isTimeSlotsModalOpened,
    timeSlotsEditorType,
    handleOpenTimeSlotsModal,
    handleCloseTimeSlotsModal,
  } = useAddTimeSlotsModal();
  const {
    isConfirmationModalOpen,
    modalInfo,
    handleOpenModal,
    handleCloseModal,
  } = useConfirmationModal();
  const isSpecialistPage = schedulePageType === SCHEDULE_PAGES.SPECIALIST;
  const isServicePage = schedulePageType === SCHEDULE_PAGES.SERVICE;
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const {
    servicesList: { list: services },
  } = useSelector((state: RootState) => state.services);
  const { timeSlotsList, loading: isLoadingTimeSlots } = useSelector(
    (state: RootState) => state.timeSlots,
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getTimeSlots({
          ...timeSlotsList.params,
          ...(isServicePage ? { service_id: id } : {}),
          ...(isSpecialistPage ? { specialist_id: id } : {}),
        }),
      );

      if (isSpecialistPage) {
        dispatch(
          getBuildings({
            page_size: 10000,
            specialist: id,
          }),
        );
        dispatch(
          getServices({
            specialist: id,
          }),
        );
      }
    }

    return () => {
      dispatch(clearTimeSlotsList());
      dispatch(clearServicesList());
    };
  }, []);

  const handleFiltersChange = (params: GetTimeSlotsParams) => {
    const timeSlotParams = {
      ...timeSlotsList.params,
      ...params,
    };

    if (isSpecialistPage || isServicePage) {
      timeSlotParams.specialist_id = id;
    }

    dispatch(getTimeSlots(timeSlotParams));
  };

  const timeSlotsMapped: [string, TimeSlot[]][] = useMemo(() => {
    return timeSlotsList.list ? Object.entries(timeSlotsList.list) : [];
  }, [timeSlotsList.list]);

  return (
    <S.Container>
      {isLoadingTimeSlots && <OverlayLoader />}
      <S.FiltersWrapper>
        <WeekFilter
          handleFiltersChange={handleFiltersChange}
          params={timeSlotsList.params}
          isLoading={isLoadingTimeSlots}
        />
        <S.ButtonsWrapper>
          {Boolean(buildings?.result?.length) && (
            <FiltersStyles.FilterItem>
              <BuildingFilter
                buildings={buildings.result}
                onFiltersChange={handleFiltersChange}
              />
            </FiltersStyles.FilterItem>
          )}
          {Boolean(services?.result.length) && isSpecialistPage && (
            <FiltersStyles.FilterItem>
              <ServiceFilter
                services={services?.result}
                onFiltersChange={handleFiltersChange}
              />
            </FiltersStyles.FilterItem>
          )}
          <CustomButton
            title="Add working time"
            variant="buttonMedium"
            color="success"
            beforeIcon={CheckMarkIcon}
            handleClick={() =>
              handleOpenTimeSlotsModal(TIME_SLOTS_EDITOR_TYPES.AVAILABLE_TIME)
            }
          />
          <CustomButton
            title="Add time-off"
            variant="buttonMedium"
            color="error"
            beforeIcon={ErrorIcon}
            handleClick={() =>
              handleOpenTimeSlotsModal(TIME_SLOTS_EDITOR_TYPES.UNAVAILABLE_TIME)
            }
          />
        </S.ButtonsWrapper>
      </S.FiltersWrapper>
      <S.ListWrapper>
        <TableContainer component={Paper} sx={TABLE_CONTAINER_STYLES}>
          <Table sx={TABLE_STYLES} aria-label="simple table">
            <TableHead>
              <TableRow>
                {getScheduleListConfig(isSpecialistPage).map(
                  (item: ColumnType<TimeSlot>) => (
                    <Fragment key={item.label}>
                      {item.headerComponent(item)}
                    </Fragment>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {timeSlotsMapped.map((day: [string, TimeSlot[]]) =>
                day[1].length ? (
                  day[1].map((timeSlot: TimeSlot, index: number) => (
                    <TableRow
                      sx={{
                        backgroundColor:
                          timeSlot.status === TIME_SLOT_STATUSES.UNAVAILABLE
                            ? 'rgba(244, 67, 54, 0.08)'
                            : 'white',
                      }}
                      hover={true}
                      key={timeSlot.id}
                    >
                      {getScheduleListConfig(isSpecialistPage).map(
                        (item: ColumnType<TimeSlot>) => (
                          <Fragment key={item.label}>
                            {item.label === 'Actions'
                              ? item.cellComponent(
                                  item,
                                  timeSlot,
                                  <ScheduleListActions
                                    timeSlot={timeSlot}
                                    handleOpenModal={handleOpenModal}
                                    serviceOrSpecialistId={id}
                                    isSpecialistPage={isSpecialistPage}
                                  />,
                                  undefined,
                                  index !== day[1].length - 1,
                                )
                              : item.cellComponent(
                                  item,
                                  timeSlot,
                                  undefined,
                                  index,
                                  index !== day[1].length - 1,
                                )}
                          </Fragment>
                        ),
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    }}
                    key={day[0]}
                  >
                    <CellElement<TimeSlot>
                      column={getScheduleListConfig(isSpecialistPage)[0]}
                    >
                      <Typography variant={'tableHeader'}>
                        {capitalizeFirstLetter(day[0])}
                      </Typography>
                    </CellElement>
                    <CellElement<TimeSlot>
                      column={getScheduleListConfig(isSpecialistPage)[0]}
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                        variant={'body2'}
                      >
                        No working hours for this day
                      </Typography>
                    </CellElement>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <YesNoModal
          isOpen={isConfirmationModalOpen}
          title={modalInfo.title}
          question={modalInfo.question}
          closeModal={handleCloseModal}
          handleAction={() => {
            if (modalInfo.callback) {
              modalInfo.callback();
            }
            handleCloseModal();
          }}
        />
        <Modal
          open={isTimeSlotsModalOpened}
          onClose={handleCloseTimeSlotsModal}
        >
          <Fragment>
            {id && (
              <AddScheduleForm
                editorType={timeSlotsEditorType}
                handleCloseTimeSlotsModal={handleCloseTimeSlotsModal}
                serviceOrSpecialistId={id}
                isSpecialistPage={isSpecialistPage}
              />
            )}
          </Fragment>
        </Modal>
      </S.ListWrapper>
    </S.Container>
  );
};
