import { ReactNode } from 'react';

import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { makeStyles } from '@mui/styles';
import { OverridableStringUnion } from '@mui/types';
import classNames from 'classnames';

import { theme } from '../../theme/theme';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '120px',
    borderRadius: '26px',
    padding: '15px',
    border: 'none',
    backgroundColor: '#D5A419',
    cursor: 'pointer',
  },
  noBackground: {
    background: 'none',
    color: '#D5A419',
    padding: 0,
    '&:disabled': {
      opacity: 0.4,
    },
  },
  noBackgroundBlack: {
    background: 'none',
    color: '#000',
    padding: 0,
    '&:disabled': {
      opacity: 0.4,
    },
  },
  buttonWhite: {
    backgroundColor: '#FFFFFF',
  },
  buttonGray: {
    backgroundColor: '#F3F3F3',
  },
  buttonDisabled: {
    backgroundColor: '#EEEEEE',
    color: '#757575',
    pointerEvents: 'none',
  },
  buttonBlack: {
    backgroundColor: '#000000',
    color: 'white',
  },
  buttonSuccess: {
    border: `1px solid ${theme.palette.success.dark}`,
    color: theme.palette.success.main,
    backgroundColor: '#EDF7ED',
  },
  buttonError: {
    border: `1px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.main,
    backgroundColor: '#FEECEB',
  },
  buttonSm: {
    minWidth: '78px',
  },
}));

interface CustomButtonProps {
  title: string;
  beforeIcon?: string | ReactNode;
  afterIcon?: string | ReactNode;
  type?: 'button' | 'submit';
  size?: 'sm' | 'md';
  handleClick?: (e: React.MouseEvent) => void;
  color?:
    | 'primary'
    | 'secondary'
    | 'disabled'
    | 'gray'
    | 'black'
    | 'success'
    | 'error'
    | 'noBackground'
    | 'noBackgroundBlack';
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  additionalClass?: string;
  disabled?: boolean;
}

export const CustomButton = ({
  title,
  type = 'button',
  handleClick,
  color = 'primary',
  beforeIcon,
  afterIcon,
  variant,
  additionalClass,
  size = 'md',
  disabled = false,
}: CustomButtonProps) => {
  const classes = useStyles({ color });
  return (
    <button
      type={type}
      disabled={disabled}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      onClick={(e: React.MouseEvent) => handleClick && handleClick(e)}
      className={classNames(classes.button, additionalClass, {
        [classes.buttonWhite]: color === 'secondary',
        [classes.noBackground]: color === 'noBackground',
        [classes.noBackgroundBlack]: color === 'noBackgroundBlack',
        [classes.buttonGray]: color === 'gray',
        [classes.buttonDisabled]: color === 'disabled',
        [classes.buttonSuccess]: color === 'success',
        [classes.buttonError]: color === 'error',
        [classes.buttonBlack]: color === 'black',
        [classes.buttonSm]: size === 'sm',
      })}
    >
      <Typography
        variant={variant}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {typeof beforeIcon === 'string' ? (
          <img src={beforeIcon} alt={'icon'} style={{ marginRight: '8px' }} />
        ) : (
          beforeIcon
        )}
        {title}
        {typeof afterIcon === 'string' ? (
          <img src={afterIcon} alt={'icon'} style={{ marginLeft: '8px' }} />
        ) : (
          afterIcon
        )}
      </Typography>
    </button>
  );
};
