export const MAX_FILE_SIZE_AVATAR = 500000;
export const MAX_IMAGES_COUNT = 4;
export const SUPPORTED_FILE_EXTENSIONS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
];

export const TABLE_CONTAINER_STYLES = { borderRadius: '20px', minHeight: 576 };
export const TABLE_STYLES = { minWidth: 650, tableLayout: 'fixed' };

export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm aaa';
export const DATE_TIME_WEEKDAY_SHORT_FORMAT = 'ccc - MM/dd/yyyy - hh:mm aaa';
export const DATE_FORMAT_BE = 'yyyy-MM-dd';
export const DATE_FORMAT_WEEK_FILTER = 'd MMM y';
export const TIME_FORMAT_BE = 'HH:mm';
export const TIME_FORMAT_AM_PM = 'hh:mm aaa';

export const VALIDATE_IMAGE_MESSAGE = `Image max size should be less than ${
  MAX_FILE_SIZE_AVATAR / 1000
}kb. Image format should be png or jpeg.`;

export const MAX_IMAGES_COUNT_MESSAGE = 'Max images is 4';
