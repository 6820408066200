import styled from '@emotion/styled/macro';

export const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 300px;
  background-color: white;
  border-radius: 20px;
  padding: 40px 0px 20px 30px;
`;

export const ScrollableContainer = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 30px;
`;

export const Header = styled.div`
  font-family: 'GraphikMedium', sans-serif;
  font-size: 24px;
  margin-bottom: 24px;
`;

export const Close = styled.button`
  border: none;
  box-shadow: none;
  background: none;
  position: absolute;
  right: 30px;
  top: 35px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  button {
    margin-left: 10px;
  }
`;

export const SelectDayWrapper = styled.div`
  margin-bottom: 24px;
`;

export const SelectedIntervals = styled.div`
  margin-bottom: 24px;
`;

export const SelectedInterval = styled.div`
  height: 53px;
  display: flex;
  margin-bottom: 10px;
  padding-left: 10px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: ${(props: any) => props.theme.palette.grey[200]};
  border-radius: 10px;

  & > span {
    margin: 0 4px;
    font-size: 14px;
    font-family: 'GraphikMedium', sans-serif;
  }
`;

export const DeleteInterval = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;
