import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import {
  DateTimePicker,
  DigitalClock,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';

import * as S from './FormDateTimePicker.styled';

interface FormDateTimePickerProps {
  disabled?: boolean;
  format?: string;
  label: string;
  minDateTime?: Date;
  name: string;
  onChange?: (value: Date) => void;
  shouldDisableDate?: (day: Date) => boolean;
  shouldDisableTime?: (time: Date) => boolean;
  value?: Date | null;
}

export const FormDateTimePicker = ({
  disabled,
  format,
  label,
  minDateTime,
  name,
  value,
  onChange,
  shouldDisableDate,
  shouldDisableTime,
}: FormDateTimePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              closeOnSelect={false}
              disabled={disabled}
              format={format}
              label={label}
              minDate={DateTime.now().toJSDate()}
              onAccept={() => {
                field.onBlur();
              }}
              onChange={(value) => {
                if (onChange) {
                  onChange(value as Date);
                }
                field.onChange(value);
              }}
              onError={() => {
                field.onBlur();
              }}
              shouldDisableDate={shouldDisableDate}
              value={field.value}
              viewRenderers={{
                // Override the default time view with a digital clock
                hours: () => (
                  <DigitalClock
                    disableIgnoringDatePartForTimeValidation={true}
                    minTime={minDateTime}
                    shouldDisableTime={shouldDisableTime}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    skipDisabled
                    timeStep={15}
                    value={field.value}
                  />
                ),
              }}
            />
          )}
        />
        {errors[name] && (
          <Typography
            className="error-message"
            color="error"
            variant="helperText"
            paddingLeft=".75rem"
          >
            <>{errors?.[name]?.message}</>
          </Typography>
        )}
      </LocalizationProvider>
    </S.Container>
  );
};
