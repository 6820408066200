import styled from '@emotion/styled/macro';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const SelectedCombination = styled.div`
  height: 53px;
  border-radius: 10px;
  margin-bottom: 8px;
  background-color: ${(props: any) => props.theme.palette.grey[200]};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    margin-left: 16px;
  }
`;

export const Info = styled.div`
  padding: 0 16px;
  max-width: 100%;
`;

export const Building = styled.div`
  font-size: 16px;
  font-family: 'GraphikMedium', sans-serif;
`;

export const Services = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const DeleteButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  margin-top: 2px;
  cursor: pointer;
  margin-right: 8px;
`;
