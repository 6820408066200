import * as yup from 'yup';

export const getAddScheduleFormSchema = (
  isSpecialistPage: boolean | undefined,
) => {
  const schema = yup.object({
    services_buildings: yup
      .array()
      .when('service_buildings', {
        is: () => !isSpecialistPage,
        then: yup
          .array()
          .required()
          .min(1, 'At least one building should be chosen.'),
      })
      .when('service_buildings', {
        is: () => isSpecialistPage,
        then: yup
          .array()
          .required()
          .min(
            1,
            'At least one building-services combination should be selected.',
          ),
      }),
    specialist_services_buildings: yup.array(),
    start_date: yup.date().nullable(),
    end_date: yup.date().nullable(),
    timeslots: yup
      .array()
      .required()
      .min(1, 'At least one time slot should be chosen.'),
    min_duration: yup.number().required(),
  });

  return schema;
};
