import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { CustomButton } from 'components';
import {
  MultiSelectWrapper,
  SelectItem,
} from 'components/MultiSelectWrapper/MultiSelectWrapper';
import { SelectWrapper } from 'components/SelectWrapper/SelectWrapper';
import { useAppDispatch } from 'hooks/useAppSelector';

import { RootState } from 'store/rootReducer';
import { getServices } from 'store/services/services.actions';
import { Building } from 'types/buildings';

import * as SharedS from '../../ScheduleForms.styled';
import {
  mapBuildingsForSelect,
  mapServicesForSelect,
} from '../AddScheduleForm.helpers';
import { LocationServices } from '../AddScheduleForm.types';
import { LOCATION_SERVICES_DEFAULT } from './LocationServiceCombined.constants';
import * as S from './LocationServiceCombined.styled';
import { SelectedCombinations } from './SelectedCombinations/SelectedCombinations';

interface LocationServiceCombinedProps {
  name: string;
  buildings: { result: Building[] };
  specialist_id: string;
}

export const LocationServiceCombined = ({
  name,
  buildings,
  specialist_id,
}: LocationServiceCombinedProps) => {
  const dispatch = useAppDispatch();
  const [allServicesList, setAllServicesList] = useState<SelectItem[]>([]);
  const { servicesList, loading: isServicesLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const [locationServices, setLocationServices] = useState<LocationServices>(
    LOCATION_SERVICES_DEFAULT,
  );
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (!allServicesList.length && servicesList.list?.result?.length) {
      setAllServicesList(mapServicesForSelect(servicesList.list));
    }
  }, []);

  const mappedBuildings = useMemo(
    () => mapBuildingsForSelect(buildings),
    [buildings],
  );
  const mappedServices = useMemo(() => {
    const mappedServicesForSelect = mapServicesForSelect(servicesList.list);

    const minDurations = mappedServicesForSelect.map(
      (service) => service.min_duration,
    );

    setValue('min_duration', Math.min(...minDurations));

    return mappedServicesForSelect;
  }, [servicesList, setValue]);

  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <SharedS.FormItemHeader>
                Location and Service
                <S.ButtonWrapper>
                  <CustomButton
                    title="Add"
                    color="primary"
                    size="sm"
                    disabled={
                      !locationServices.building ||
                      !locationServices.services.length
                    }
                    variant="buttonMedium"
                    handleClick={() => {
                      onChange([...value, locationServices]);
                      setLocationServices(LOCATION_SERVICES_DEFAULT);
                    }}
                  />
                </S.ButtonWrapper>
              </SharedS.FormItemHeader>
              <S.LocationWrapper>
                <SelectWrapper
                  name="building"
                  label="Building"
                  disabled={!mappedBuildings.length}
                  value={locationServices.building}
                  onChange={(selectedValue: string) => {
                    setLocationServices((prevState) => ({
                      ...prevState,
                      building: selectedValue,
                    }));
                    dispatch(
                      getServices({
                        building_id: selectedValue,
                        specialist: specialist_id,
                      }),
                    );
                  }}
                  items={mappedBuildings}
                  isItemDisabled={(item: SelectItem) =>
                    value.some(
                      (selectedItem: LocationServices) =>
                        selectedItem.building === item.value,
                    )
                  }
                />
                <MultiSelectWrapper
                  name="services"
                  label={
                    !locationServices.building
                      ? 'Select a Building'
                      : isServicesLoading
                      ? 'Loading...'
                      : !mappedServices.length
                      ? 'No Services available for this building'
                      : 'Select a Service'
                  }
                  items={mappedServices}
                  onChange={(selectValue) => {
                    setLocationServices((prevState) => ({
                      ...prevState,
                      services: selectValue,
                    }));
                  }}
                  disabled={
                    !locationServices.building ||
                    isServicesLoading ||
                    !mappedServices.length
                  }
                  value={locationServices.services}
                  hasSelectAllOption={true}
                />
              </S.LocationWrapper>
              {errors[name] && (
                <Typography
                  variant="helperText"
                  color="error"
                  className="error-message"
                  display="block"
                  paddingLeft=".75rem"
                >
                  <>{errors?.[name]?.message}</>
                </Typography>
              )}
              <S.SelectedCombinationsWrapper>
                <SelectedCombinations
                  list={value}
                  buildings={mappedBuildings}
                  services={allServicesList}
                  handleRemoveCombination={(buildingId: string) => {
                    onChange(
                      value.filter(
                        (item: LocationServices) =>
                          item.building !== buildingId,
                      ),
                    );
                  }}
                />
              </S.SelectedCombinationsWrapper>
            </>
          );
        }}
      />
    </S.Container>
  );
};
