import styled from '@emotion/styled/macro';

export const Container = styled.div``;

export const LocationWrapper = styled.div`
  & > div {
    width: 100%;
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 24px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  text-align: right;
`;

export const SelectedCombinationsWrapper = styled.div``;
