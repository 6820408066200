import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  areIntervalsOverlapping,
  format,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';

import CloseIcon from 'assets/icons/cross.svg';
import DeleteIcon from 'assets/icons/delete-grey.svg';

import { CustomButton } from 'components';
import { FormCheckbox } from 'components/HookFormWrappers/FormCheckbox/FormCheckbox';
import { FormDateTimePicker } from 'components/HookFormWrappers/FormDateTimePicker/FormDateTimePicker';
import { FormMultiSelect } from 'components/HookFormWrappers/FormMultiSelect/FormMultiSelect';
import { FormSelect } from 'components/HookFormWrappers/FormSelect/FormSelect';
import { useAppDispatch } from 'hooks/useAppSelector';

import { RootState } from 'store/rootReducer';
import {
  addTimeSlots,
  getTimeSlots,
} from 'store/time-slots/time-slots.actions';
import { DATE_TIME_WEEKDAY_SHORT_FORMAT } from 'utils/constants';

import {
  REPEATS_EVERY_OPTIONS,
  REPEATS_TYPES_OPTIONS,
  TIME_SLOTS_EDITOR_TYPES,
} from '../ScheduleForms.constants';
import * as SharedS from '../ScheduleForms.styled';
import {
  ADD_SCHEDULE_FORM_DEFAULT_VALUES,
  mapBuildingsForSelect,
  prepareDataForRequest,
} from './AddScheduleForm.helpers';
import { getAddScheduleFormSchema } from './AddScheduleForm.schema';
import * as S from './AddScheduleForm.styled';
import {
  AddTimeSlotsEditorValues,
  EditorTimeSlot,
} from './AddScheduleForm.types';
import { SelectedInterval } from './IntervalsSelector/IntervalsSelector.types';
import { LocationServiceCombined } from './LocationServiceCombined/LocationServiceCombined';

interface TimeSlotsEditorProps {
  editorType: TIME_SLOTS_EDITOR_TYPES;
  handleCloseTimeSlotsModal: () => void;
  serviceOrSpecialistId: string;
  isSpecialistPage?: boolean;
}

export const AddScheduleForm = ({
  editorType,
  handleCloseTimeSlotsModal,
  serviceOrSpecialistId,
  isSpecialistPage,
}: TimeSlotsEditorProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { buildings } = useSelector((state: RootState) => state.buildings);
  const {
    timeSlotsList: { params },
  } = useSelector((state: RootState) => state.timeSlots);

  const addScheduleForm = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getAddScheduleFormSchema(isSpecialistPage)),
    values: ADD_SCHEDULE_FORM_DEFAULT_VALUES,
  });

  const { clearErrors, formState, getValues, setError, setValue, watch } =
    addScheduleForm;

  const watchedStartDate = watch('start_date');
  const watchedEndDate = watch('end_date');
  const watchedMinDuration = watch('min_duration') as number;

  useMemo(() => {
    if (!watchedStartDate) {
      setValue('end_date', null);

      return;
    }

    const minDurationsInSeconds = watchedMinDuration * 60 * 60;

    const minEndDateTime = DateTime.fromJSDate(watchedStartDate as Date)
      .plus({ seconds: minDurationsInSeconds })
      .toJSDate();

    setValue('end_date', minEndDateTime);
  }, [setValue, watchedMinDuration, watchedStartDate]);

  const handleSubmitForm = async (values: AddTimeSlotsEditorValues) => {
    const formErrors = Object.entries(formState.errors);

    if (formErrors.length > 0) {
      formErrors.forEach(([key, error]) => {
        enqueueSnackbar(`${key} - ${error.message}`, {
          variant: 'error',
        });
      });

      return;
    }

    const addTimeSlotsRequest = prepareDataForRequest(
      values,
      editorType,
      serviceOrSpecialistId,
      isSpecialistPage,
    );

    try {
      await dispatch(addTimeSlots(addTimeSlotsRequest)).unwrap();

      enqueueSnackbar('Time slots were added successfully', {
        variant: 'success',
      });
      handleCloseTimeSlotsModal();

      await dispatch(getTimeSlots({ ...params }));
    } catch (error) {
      enqueueSnackbar(`Error: ${(error as Error).message}`, {
        variant: 'error',
      });
    }
  };

  const handleAddTimeSlot = () => {
    const currentTimeslots = getValues('timeslots');

    if (watchedStartDate && watchedEndDate) {
      if (
        isBefore(watchedEndDate, watchedStartDate) ||
        isEqual(watchedEndDate, watchedStartDate)
      ) {
        setError('end_date', {
          message: 'End date/time should be later than start date/time',
        });

        return;
      }

      if (
        currentTimeslots.length > 0 &&
        currentTimeslots.some(({ start_time, end_time }) =>
          areIntervalsOverlapping(
            {
              start: start_time,
              end: end_time,
            },
            {
              start: watchedStartDate as Date,
              end: watchedEndDate as Date,
            },
          ),
        )
      ) {
        enqueueSnackbar(
          'Error: The selected timeslot intervals overlap with previously selected timeslots',
          {
            variant: 'error',
          },
        );

        return;
      }
    }

    const timeslot: EditorTimeSlot = {
      start_time: watchedStartDate as Date,
      end_time: watchedEndDate as Date,
    };

    if (currentTimeslots.some((item) => item === timeslot)) {
      return;
    }

    const newTimeslots = [...currentTimeslots, timeslot].sort(
      (a, b) => a.start_time.valueOf() - b.start_time.valueOf(),
    );

    clearErrors('end_date');
    setValue('timeslots', newTimeslots);
    setValue('end_date', null);
  };

  const handleDeleteSelectedInterval = (startIsoString: string) => {
    setValue(
      'timeslots',
      getValues('timeslots').filter(
        (item: SelectedInterval) =>
          item.start_time.toISOString() !== startIsoString,
      ),
    );
  };

  const renderTimeSlots = () => {
    return (
      <S.SelectedIntervals>
        {watch('timeslots').map((item: SelectedInterval) => (
          <S.SelectedInterval key={item.start_time?.toISOString()}>
            <span>
              {format(item.start_time, DATE_TIME_WEEKDAY_SHORT_FORMAT)}
            </span>
            <span>to</span>
            <span>
              {item.end_time &&
                format(item.end_time, DATE_TIME_WEEKDAY_SHORT_FORMAT)}
            </span>
            <S.DeleteInterval
              onClick={() =>
                handleDeleteSelectedInterval(item.start_time.toISOString())
              }
            >
              <img src={DeleteIcon} alt="" />
            </S.DeleteInterval>
          </S.SelectedInterval>
        ))}
      </S.SelectedIntervals>
    );
  };

  return (
    <S.Container>
      <S.Close onClick={handleCloseTimeSlotsModal}>
        <img src={CloseIcon} alt="" />
      </S.Close>
      {editorType === TIME_SLOTS_EDITOR_TYPES.AVAILABLE_TIME && (
        <S.Header>Add Working Time</S.Header>
      )}
      {editorType === TIME_SLOTS_EDITOR_TYPES.UNAVAILABLE_TIME && (
        <S.Header>Add Time-off</S.Header>
      )}
      <FormProvider {...addScheduleForm}>
        <S.ScrollableContainer>
          <SharedS.LocationWrapper>
            {buildings?.result?.length > 0 && !isSpecialistPage && (
              <>
                <SharedS.FormItemHeader>Location</SharedS.FormItemHeader>
                <FormMultiSelect
                  name="services_buildings"
                  label="Buildings"
                  items={mapBuildingsForSelect(buildings)}
                  hasSelectAllOption={true}
                  disabled={!buildings.result.length}
                />
              </>
            )}
            {buildings?.result?.length > 0 && isSpecialistPage && (
              <>
                <LocationServiceCombined
                  name="services_buildings"
                  buildings={buildings}
                  specialist_id={serviceOrSpecialistId}
                />
              </>
            )}
          </SharedS.LocationWrapper>
          <SharedS.DateAndTimeWrapper>
            <SharedS.FormItemHeader>
              Date and Time{' '}
              <CustomButton
                title="Add"
                color="primary"
                disabled={!watchedStartDate || !watchedEndDate}
                variant="buttonMedium"
                size="sm"
                handleClick={handleAddTimeSlot}
              />
            </SharedS.FormItemHeader>
            <SharedS.DoubleFormItem>
              <SharedS.DatepickerWrapper>
                <FormDateTimePicker
                  format={DATE_TIME_WEEKDAY_SHORT_FORMAT}
                  label="Start date/time"
                  minDateTime={DateTime.now().toJSDate()}
                  name="start_date"
                  onChange={(value) => {
                    if (watchedEndDate && isAfter(value, watchedEndDate)) {
                      setValue('end_date', null);
                    }
                  }}
                  shouldDisableTime={(datetime) => {
                    const currentTimeslots = getValues('timeslots');

                    const timeslotsOnSelectedDay = currentTimeslots.filter(
                      (item) => item.start_time.getDay() === datetime.getDay(),
                    );

                    if (
                      timeslotsOnSelectedDay.some(
                        (item) =>
                          datetime >= item.start_time &&
                          datetime <= item.end_time,
                      )
                    ) {
                      return true;
                    }

                    return false;
                  }}
                />
                <FormCheckbox name="repeat_checkbox" label="Repeat" size={20} />
              </SharedS.DatepickerWrapper>
              <SharedS.DatepickerWrapper>
                <FormDateTimePicker
                  disabled={!watchedStartDate}
                  format={DATE_TIME_WEEKDAY_SHORT_FORMAT}
                  label="End date/time"
                  minDateTime={watchedEndDate ?? undefined}
                  name="end_date"
                  onChange={() => {
                    if (!watchedStartDate) {
                      return;
                    }
                  }}
                  shouldDisableTime={(datetime) => {
                    const currentTimeslots = getValues('timeslots');

                    const timeslotsOnSelectedDay = currentTimeslots.filter(
                      (item) => item.end_time.getDay() === datetime.getDay(),
                    );

                    if (
                      timeslotsOnSelectedDay.some(
                        (item) =>
                          datetime >= item.start_time &&
                          datetime <= item.end_time,
                      )
                    ) {
                      return true;
                    }

                    return false;
                  }}
                />
              </SharedS.DatepickerWrapper>
            </SharedS.DoubleFormItem>
            {watch('repeat_checkbox') && (
              <SharedS.RepeatEveryWrapper>
                <SharedS.FormItemHeader>Repeats every</SharedS.FormItemHeader>
                <SharedS.DoubleFormItem>
                  <FormSelect
                    name="repeat_type"
                    label="Period"
                    items={REPEATS_TYPES_OPTIONS}
                  />
                  <FormSelect
                    name="repeat_every"
                    label="Count"
                    items={REPEATS_EVERY_OPTIONS}
                  />
                </SharedS.DoubleFormItem>
              </SharedS.RepeatEveryWrapper>
            )}
            {renderTimeSlots()}
          </SharedS.DateAndTimeWrapper>
          <S.ButtonsWrapper>
            <CustomButton
              title="Cancel"
              color="secondary"
              variant="buttonMedium"
              size="sm"
              handleClick={handleCloseTimeSlotsModal}
            />
            <CustomButton
              title="Save"
              color="primary"
              variant="buttonMedium"
              size="sm"
              handleClick={addScheduleForm.handleSubmit(handleSubmitForm)}
            />
          </S.ButtonsWrapper>
        </S.ScrollableContainer>
      </FormProvider>
    </S.Container>
  );
};
